import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

export default function TextEditor({
  style = {},
  content = '',
  onChange = () => {}
}) {
  return (
    <ReactQuill
      theme='snow'
      value={content}
      onChange={onChange}
      style={style}
    />
  )
}
