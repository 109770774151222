import React from 'react'
import InputMask from 'react-input-mask'
import './style.css'

const TextField = (props) => (
  <div
    style={{
      ...props.style,
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'center'
    }}
  >
    {props.leftIcon ? props.leftIcon : false}
    <InputMask
      mask={props.mask}
      value={props.value}
      onChange={props.onChange}
      style={{
        border: 'none',
        width: '100%',
        fontSize: props.textSize ? props.textSize : 'inherent'
      }}
    >
      {(inputProps) => (
        <input
          {...inputProps}
          style={{
            border: 'none',
            width: '100%',
            fontSize: props.textSize ? props.textSize : 'inherent'
          }}
        />
      )}
    </InputMask>
    {props.rightIcon ? props.rightIcon : false}
  </div>
)

export default TextField
