import socketIOClient from 'socket.io-client'

function connect(ENDPOINT) {
  let socket = socketIOClient(ENDPOINT, {
    transports: ['websocket', 'polling']
  })

  return socket
}

export default { connect }
