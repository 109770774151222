import React from 'react'

//Modules
import http from './modules/http'
import socket from './modules/socket'
import { useNavigate } from './modules/navigate'

//Functions
import formatNumber from './functions/formartNumber'

//Components
import Router from './components/router'
import TextField from './components/TextField'
import Calendar from './components/Calendar'
import TextEditor from './components/TextEditor'

const module = {
  server: {
    http,
    socket
  },
  navigation: {
    use: useNavigate
  }
}

const functions = {
  formatNumber
}
const components = {
  Router,
  TextField,
  Calendar,
  TextEditor
}
export default { module, components, functions }
