let defaultHeaders = {}

async function post({ headers, body, url }) {
  if (!headers) headers = defaultHeaders
  let res = await fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body)
  })
  let data = await res.json()

  return { status: res.status, data: data }
}

async function get({ headers, url }) {
  if (!headers) headers = defaultHeaders
  let res = await fetch(url, { method: 'GET', headers })
  let data = await res.json()

  return { status: res.status, data: data }
}

async function authenticate({ headers, url, body }) {
  let res = await post({ headers, url, body })

  if (res.status !== 200)
    return { status: res.status, error: 'Not Authenticate' }
  defaultHeaders = { Authorization: 'Bearer ' + res.data.info.token }
  return { status: res.status, data: res.data }
}

let utils = {
  authenticate
}

export default {
  post,
  get,
  utils
}
