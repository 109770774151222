import React from 'react'
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  Outlet,
  Navigate
} from 'react-router-dom'
function CustomRouter({ routes }) {
  const PrivateRoute = ({ redirectTo, condition }) => {
    return condition() ? <Outlet /> : <Navigate to={redirectTo} />
  }

  return (
    <Router>
      <Switch>
        {routes.map((route) => {
          if (route.private) {
            return (
              <Route
                exact
                path={route.path}
                element={
                  <PrivateRoute
                    redirectTo={route.redirectTo}
                    condition={route.condition}
                  />
                }
              >
                <Route exact path={route.path} element={route.page} />
              </Route>
            )
          } else {
            return <Route exact path={route.path} element={route.page} />
          }
        })}
      </Switch>
    </Router>
  )
}
export default CustomRouter
