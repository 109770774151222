import { Calendar, momentLocalizer, Views } from 'react-big-calendar'
import moment from 'moment'
import React from 'react'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const CustomCalendar = ({ style, events, defaultDate, onSelectEvent }) => {
  const localizer = momentLocalizer(moment)

  return (
    <Calendar
      style={style}
      events={events}
      localizer={localizer}
      defaultDate={defaultDate}
      onSelectEvent={onSelectEvent}
    />
  )
}
export default CustomCalendar
